<template>
	<v-container fluid class="contact-wrapper">
		<v-row class="contact-wallpaper" align="center" justify="center">
			<v-col cols="12" sm="10" md="6" lg="4" xl="3">
				<v-card class="mx-auto my-8 pb-2" elevation="1">
					<v-card-title>Fale Conosco</v-card-title>
					<v-card-subtitle>Começe uma conversa no whatsapp com a gente</v-card-subtitle>
					<v-divider></v-divider>

					<v-card-text class="mt-4 px-8">
						<v-text-field clearable dense filled counter v-model="name" :rules="nameRules" ref="iname" color="orange darken-2" prepend-inner-icon="person" label="Nome" maxlength="25"></v-text-field>
						<v-text-field clearable dense filled counter v-model="subject" :rules="subjectRules" ref="isubject" color="orange darken-2" prepend-inner-icon="message" label="Assunto" maxlength="25"></v-text-field>
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn @click.prevent="talk()" text color="orange darken-2" class="mr-4">
							iniciar conversa <v-icon right>send</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'contact',
  
	methods: {
		talk() {
			if(!this.name) return this.$nextTick(() => this.$refs.iname.focus())
			if(!this.subject) return this.$nextTick(() => this.$refs.isubject.focus())

			const uri = encodeURIComponent(`Olá, meu nome é ${this.name} e eu gostaria de falar sobre ${this.subject}`)
			open('https://wa.me/5516988275392?text=' + uri, '_blank')
			this.$logEvent('talk_clicked')
			
			this.$refs.iname.reset()
			this.$refs.isubject.reset()
		}
	},
  
  	data() {
    	return {
			name: '', subject: '', 
			nameRules: [
				value => !!value || 'Preencha esse campo.',
				value => (value && value.length >= 3) || 'Esse nome é muito pequeno.',
			],
			subjectRules: [
				value => !!value || 'Preencha esse campo.',
				value => (value && value.length >= 3) || 'O assunto é muito pequeno.',
			]
		}
  	}
}
</script>

<style lang="scss">
.contact-wrapper{
	height: 100%;
}

.contact-wallpaper {
	height: calc(100% + 24px);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: radial-gradient(#ffffff88, #00000088), url(../assets/slides/2.jpg);
}
</style>